<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item label="Excel名称">
				<el-input v-model="formData.name" placeholder="文件名称" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search">查询</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column type="index" width="50" align="center" label="序号"> </el-table-column>
			<el-table-column prop="name" header-align="center" align="center" label="名称"></el-table-column>
			<el-table-column prop="" header-align="center" align="center" label="下载地址">
				<template slot-scope="scope">
					<a :href="scope.row.url" target="_blank">下载Excel</a>
				</template>
			</el-table-column>
			<el-table-column prop="status" header-align="center" align="center" label="状态">
				<template slot-scope="scope">
					{{ scope.row.status === 1 ? '文件生成中' : '导出成功' }}
				</template>
			</el-table-column>
			<!-- <el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-popconfirm v-if="scope.row.status === 0" title="确认进行退款？"
						@confirm="refund(scope.row)">
						<el-button style="margin-left: 10px" slot="reference" type="text" size="small">退款</el-button>
					</el-popconfirm>
				</template>
			</el-table-column> -->
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	data () {
		return {
			formData: {
				name: ''
			},
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('excelList', {
				name: this.formData.name,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows;
				}
			});
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		}
	}
};
</script>